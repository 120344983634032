<template>
    <square-loader class="modal" v-if="loading"></square-loader>
    
    <div class="w-full h-auto bg-brown-default slide-in-top">
        <div class="w-2/3 md:w-1/2 lg:w-1/3 mx-auto py-32 ">
            <form @submit.prevent="login()"  class="flex flex-col bg-white bg-opacity-20 p-8 rounded-xl shadow-lg">
                <img src="@/assets/images/login.jpg" alt="login image" loading="eager" class="rounded-full w-16 object-center place-self-center">
                
                <label class="text-3xl py-4">Admin Login</label>
                <div class="px-4">
                    <input type="text" v-model="email" class="w-full appearance-none bg-white text-gray-500 bg-opacity-30 outline-none py-2 px-4 rounded-md focus:bg-gray-50 focus:opacity-30 placeholder-gray-200" placeholder="Email" required>
                </div>
                <div class="py-4 px-4">
                    <input type="password" v-model="password" class="w-full appearance-none bg-white text-gray-500 bg-opacity-30 outline-none py-2 px-4 rounded-md focus:bg-gray-50 focus:opacity-30 placeholder-gray-200" placeholder="Password" required>
                </div>
                <div class="py-4 px-4">
                    <button type="submit" class="w-full bg-black bg-opacity-60 text-white outline-none py-2 px-4 rounded-lg">
                        Login
                        <!-- <svg v-if="loading" class="w-6 h-6 inline-block rounded-full animate-spin object-cover object-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg> -->
                    </button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SquareLoader from '@/components/loaders/SquareGrid.vue'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default {
    name: 'Signin',
    components: {
        SquareLoader
    },
    
    data() {        
        return {
            email: '',
            password: '',            
        }
    },
    computed: {
        ...mapGetters({
            loading: 'authModule/getLoading',
            role: 'authModule/getRole'
        })
    },
    
    methods: {
        async login() {
            const data = { email: this.email, password: this.password }
            await this.$store.dispatch('authModule/authRequest', data)
            .then(() => {
                if (this.role == 'admin') {
                    
                    Toast.fire({
                        icon: 'success',
                        title: 'Signed in successfully'
                    })
                    this.$router.push('/admin/dashboard')
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Server Error',
                        text: "Unabled to log you in..."
                    })
                    if (this.role != null) {
                        this.$store.dispatch('authModule/logoutRequest')
                    }
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error',
                    text: "Check network connection..."
                })
                if (this.role != null) {
                    this.$store.dispatch('authModule/logoutRequest')
                }
            })
        }
    }

}
</script>

<style scoped>
    .slide-in-top {
        -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
        0% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slide-in-top {
        0% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    }

</style>